import { ADD_REGION, ADD_ROLE, ADD_ROLE_SUCCESS, ADD_TO_CART, ADD_USER, ADD_USER_SUCCESS, ADD_ZONE, ADD_ZONE_SUCCESS, EMPTY_CART, GET_ALL_METER, GET_ALL_METER_SUCCESS, GET_ALL_METER_TOKEN, GET_ALL_METER_TOKEN_SUCCESS, GET_ALL_REGION, GET_ALL_REGION_SUCCESS, GET_ALL_ROLE, GET_ALL_ROLE_SUCCESS, GET_ALL_SITE, GET_ALL_SITE_SUCCESS, GET_ALL_SITE_TOKEN, GET_ALL_SITE_TOKEN_SUCCESS, GET_ALL_USER, GET_ALL_USER_SUCCESS, GET_ALL_ZONE, GET_ALL_ZONE_SUCCESS, GET_PRODUCT, GET_TOKEN_BY_METER, GET_TOKEN_BY_METER_SUCCESS, REMOVE_TO_CART, UPLOAD_USER_SUCCESS } from "../../constants/ActionTypes";

export const fetchSite = () => {
    console.warn("u fetch site");
    return {
        'type': GET_ALL_SITE,
    }
}

// export const fetchSite = () => async (dispatch) => {
//     try {
//       const response = await fetch('YOUR_API_ENDPOINT');
//       const data = await response.json();
//       dispatch({ type: 'SET_SITE_DATA', payload: data });
//     } catch (error) {
//       console.error('Failed to fetch data', error);
//     }
//   };

export const fetchSiteSuccess = (data) => {
    console.warn("u fetch site", data);
    return {
        'type': GET_ALL_SITE_SUCCESS,
    }
}

export const fetchUser = () =>{
    console.warn("u fetch user");
    return {
        "type": GET_ALL_USER
    }
}
export const fetchUserSuccess = () =>{
    console.warn("u fetch user");
    return {
        "type": GET_ALL_USER_SUCCESS
    }
}
export const fetchRole = () => {
    console.warn("u fetch roles");
    return {
        'type': GET_ALL_ROLE
    }
}
export const fetchRoleSuccess = () => {
    console.warn("u fetch roles");
    return {
        'type': GET_ALL_ROLE_SUCCESS
    }
}

export const fetchZone = ( )=> {
    console.warn('u fetch zones');
    return{
        'type': GET_ALL_ZONE
    }
}
export const fetchZoneSuccess = ( )=> {
    console.warn('u fetch zones');
    return{
        'type': GET_ALL_ZONE_SUCCESS
    }
}

export const fetchRegion = () =>{
    console.warn('u fetch region');
    return{
        'type': GET_ALL_REGION
    }
}
export const fetchRegionSuccess = () =>{
    console.warn('u fetch region');
    return{
        'type': GET_ALL_REGION_SUCCESS
    }
}

export const fetchMeter = () =>{
    console.warn('u fetch region');
    return{
        'type': GET_ALL_METER
    }
}
export const fetchMeterSuccess = () =>{
    console.warn('u fetch region');
    return{
        'type': GET_ALL_METER_SUCCESS
    }
}

export const fetchMeterToken = (id)=>{
    return{
        'type': GET_ALL_METER_TOKEN,
        id
    }
}
export const fetchMeterTokenSuccess=(data)=>{
    return{
        'type':GET_ALL_METER_TOKEN_SUCCESS,
    }
}

export const fetchSiteToken=(id)=>{
    return{
        'type':GET_ALL_SITE_TOKEN,
        id
    }
}
export const fetchSiteTokenSuccess=(data)=>{
    return{
        'type':GET_ALL_SITE_TOKEN_SUCCESS
    }
}

export const fetchTokenMeter= (id)=>{
    return{
        'type':GET_TOKEN_BY_METER,
        id
    }
}
export const fetchTokenMeterSuccess= (data)=>{
    return{
        'type':GET_TOKEN_BY_METER_SUCCESS
    }
}


// Add functions
export const addUser = (data)=>{
    return{
        'type':ADD_USER,
        data
    }
}
export const addUserSuccess=()=>{
    return{
        'type':ADD_USER_SUCCESS
    }
}
export const uploadUser=(file)=>{
    return{
        'type':UPLOAD_USER_SUCCESS,
        file
    }
}
export const uploadUserSuccess=()=>{
    return{
        'type':uploadUserSuccess
    }
}

export const addRole=(data)=>{
    return{
        'type':ADD_ROLE,
        data
    }
}
export const addRoleSuccess=()=>{
    return{
        'type':ADD_ROLE_SUCCESS
    }
}
export const addZone=(data)=>{
    return{
        'type':ADD_ZONE,
        data
    }
}
export const addZoneSuccess=()=>{
    return{
        'type':ADD_ZONE_SUCCESS
    }
}
export const addRegion =(data)=>{
    return{
    'type': ADD_REGION,
    data
    }
}



export const fetchProduct = () =>{
    console.warn("u fetch product");
    return {
        "type": GET_PRODUCT
    }
}

export const addToCart = (product) =>{
    console.warn('add cart');
    return{
        'type': ADD_TO_CART,
        product
    }
}
export const removeCart =(product)=>{
    console.warn('remove from cart action', product);
    return{
        'type': REMOVE_TO_CART,
        product
    }
}
export const emptyCart = ()=>{
    console.warn('empty cart');
    return{
        'type': EMPTY_CART,
    }
}