// import antdEN from "antd/lib/locale-provider/en_US";
import enMessages from "../locales/en_US.json";
import enUS from 'antd/es/locale/en_US'; // Correct path

const EnLang = {
  messages: {
    ...enMessages
  },
  antd: enUS,
  locale: 'en-US',
};
export default EnLang;
