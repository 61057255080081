import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {


  return (
    < div className="gx-main-content-wrapper" >
      <Switch>
        <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
        <Route path={`${match.url}sites/:id`} component={asyncComponent(() => import('./Sites/siteDetails'))} />
        <Route path={`${match.url}eng/:id`} component={asyncComponent(() => import('./Engineer/engDetails'))} />
        <Route path={`${match.url}contractor/:id`} component={asyncComponent(() => import('./Contractor/contrDetails'))} />
        <Route path={`${match.url}meters/:id`} component={asyncComponent(() => import('./Meter/meterDetails'))} />
        <Route path={`${match.url}reading/:id`} component={asyncComponent(() => import('./Meter/meterReadings'))} />
        <Route path={`${match.url}sites`} component={asyncComponent(() => import('./Sites'))} />
        <Route path={`${match.url}add-merchant`} component={asyncComponent(() => import('./Sites/addCustomer'))} />
        <Route path={`${match.url}add-merchants`} component={asyncComponent(() => import('./Sites/addCustomers'))} />
        <Route path={`${match.url}agents`} component={asyncComponent(() => import('./Agent'))} />
        <Route path={`${match.url}transactions/details/:id`} component={asyncComponent(() => import('./Transactions/components/transactionDetails'))} />
        <Route path={`${match.url}transactions/all`} component={asyncComponent(() => import('./Transactions'))} />
        <Route path={`${match.url}transactions/disbursements`} component={asyncComponent(() => import('./Transactions/disbursement'))} />
        <Route path={`${match.url}transactions/collections`} component={asyncComponent(() => import('./Transactions/collections'))} />
        <Route path={`${match.url}engineers`} component={asyncComponent(() => import('./Engineer'))} />
        <Route path={`${match.url}contractor`} component={asyncComponent(() => import('./Contractor'))} />
        <Route path={`${match.url}users`} component={asyncComponent(() => import('./Users'))} />
        <Route path={`${match.url}logs/:id`} component={asyncComponent(() => import('./Logs/walletDetails'))} />
        <Route path={`${match.url}wallet/:id`} component={asyncComponent(() => import('./Wallet/walletDetails'))} />
        <Route path={`${match.url}wallet`} component={asyncComponent(() => import('./Wallet'))} />
        <Route path={`${match.url}wallet-history`} component={asyncComponent(() => import('./Wallet/walletHistory'))} />
        <Route path={`${match.url}meter`} component={asyncComponent(() => import('./Logs'))} />
        <Route path={`${match.url}reading`} component={asyncComponent(() => import('./Reading'))} />
        <Route path={`${match.url}logs`} component={asyncComponent(() => import('./Logs/logsHistory'))} />
        <Route path={`${match.url}site`} component={asyncComponent(() => import('./MerchantReference'))} />
        <Route path={`${match.url}manage-region`} component={asyncComponent(() => import('./Region'))} />
        <Route path={`${match.url}manage-zone`} component={asyncComponent(() => import('./Zone'))} />
        <Route path={`${match.url}manage-role`} component={asyncComponent(() => import('./Role'))} />
        <Route path={`${match.url}token`} component={asyncComponent(() => import('./token'))} />
        <Route path={`${match.url}test`} component={asyncComponent(() => import('./Test'))} />                                                    
      </Switch>
    </div >                                                                                                                                                                                                                                                                           
  )
};

export default App;
