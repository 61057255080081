import axios from "axios";

const axios_ = axios.create({
  // UAT
  baseURL: `https://httluku.ewallet-dev.com/api`

  
  // baseURL: `https://155.12.30.122:9893/api`

  // Production
  // baseURL: `https://epgportal.ewallet-dev.com/epg`,
  // baseURL: `https://httluku.ewallet-dev.com`,
  // baseURL: `http://localhost:8005/epg`
});

axios_.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
  config.params = config.params || {};
  config.headers['Content-type'] = `application/json`
  config.headers['Authorization'] = `Bearer ${token}`
  return config;
});

export default axios_;
