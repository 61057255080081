import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import apiSagas from "./Api";
// import productSaga from "./Api";
// import notesSagas from "./Notes";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    apiSagas(),
    // productSaga(),
    // notesSagas()
  ]);
}
