import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import { showAuthMessage, userSignIn, userSignInSuccess, userSignOutSuccess } from "../../appRedux/actions/Auth";
import axios_ from "../../util/axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


// const history = useHistory();

const loginRequest = async (userName, password) => {
  const payload = {
    userName, password
  }

  return await axios_.post("/auth/login", payload)
    .then(authUser => {
      console.log(authUser.data);
      return authUser.data
    })
    .catch(error => {
      console.log(error);
      return error.response.data
    });
}

// Create the Sign-Up Request Function
const signUpRequest = async (username, email, password, roleName, msisdn, name) => {
  const payload = {
    username, email, password, roleName, msisdn, name
  }
  return await axios_.post("/auth/register", payload)
    .then(response => {
      console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
};

// const signOutRequest = async () =>
//   await  auth.signOut()
//     .then(authUser => authUser)
//     .catch(error => error);


function* signInUserWithEmailPassword({ payload }) {
  const { userName, password } = payload;
  try {
    const signInUser = yield call(loginRequest, userName, password);
    const signInUserr = { "roles": [], "token": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJNaGFnYW1hdGhlU2VsbGVyIiwicm9sZXMiOltdLCJleHAiOjE3MDg2MTkyMDEsImlhdCI6MTcwODU1OTIwMX0.AskwoyV2zUkndSD-4BzfrKGxok5OQLssAZhUab72tyQzMg_dc8PuXYQweiA37B_5Kv33GL5PlYgbJdvO1DDv2Q", "user": { "id": 1, "userName": "MhagamatheSeller", "isActive": 1, "lastLogin": "2024-02-21T23:46:41.359+00:00", "userCreateDate": "2024-02-15T16:55:23.000+00:00", "notificationPermission": true, } }
    console.log("This is signin user", signInUser)
    if (signInUser) {
      console.log("This is signin user", signInUser)
      console.log("This is signin user", signInUser.roles)
      localStorage.setItem("signInUser", JSON.stringify(signInUser));
      localStorage.setItem("user_id", signInUser.user.id)
      localStorage.setItem("token", signInUser.token)
      localStorage.setItem("user", JSON.stringify(signInUser.user))
      console.log("Stored user in localStorage:", localStorage.getItem("signInUser"));
      yield put(userSignInSuccess(signInUser));
      console.log("Stored user in localStorage:after", localStorage.getItem("signInUser"));
    } else {
      yield put(showAuthMessage(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage("Authentication failed. Invalid username or password."));
  }
}

// Create the Sign-Up Saga Worker
function* signUpUserWithEmailPassword({ payload }) {
  const { username, email, password, roleName, msisdn, name } = payload;

  try {
    const signUpUser = yield call(signUpRequest, username, email, password, roleName, msisdn, name);
    console.log("This is signup user", signUpUser);
    if (signUpUser) {
      // Handle successful sign-up logic here
      // For example, you might want to redirect the user to the login page
      // or show a success message.
      // yield put(history.push('/login')); // Redirect to the login page
      yield put(userSignIn(signUpUser));
      console.log("This is after signup user", signUpUser);
    } else {
      yield put(showAuthMessage(signUpUser));
    }
  } catch (error) {
    yield put(showAuthMessage("Sign-up failed. Please try again."));
  }
}


function* signOut() {
  try {
    const signOutUser = null;
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload()
    } else {
      yield put(showAuthMessage(signOutUser.message));
      localStorage.removeItem('user_id');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload()
    }
  } catch (error) {
    console.log(error.response)
    yield put(showAuthMessage(error));
  }
}


export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

// Export the Sign-Up Saga Worker
export function* signUpUser() {
  yield takeEvery(SIGNUP_USER, signUpUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signUpUser),
    fork(signOutUser)]);
}
