import { call, put } from "redux-saga/effects";
import { GET_ALL_USER_SUCCESS, SET_PRODUCT } from "../../../constants/ActionTypes";
import axios_ from "../../../util/axios";

export function* handleGetUser() {
    try {
        const response = yield axios_.get('/user/getAll')
        const { data } = response
        yield put({ GET_ALL_USER_SUCCESS, data })
    } catch (error) {

    }
}
export function* handleGetSite() {
    try {
        const resp = yield call(axios_.get, '/site/getAll');
        const data = resp.data;
        console.log('Site Saga data called:', data);
        yield put({ type: 'GET_ALL_SITE_SUCCESS', data });
    } catch (error) {
        console.error('Error fetching site data:', error);
    }
}

export function* handleGetProduct() {
    const data = yield [
        {
            'type': 'phone',
            'name': 'redmi',
            'brand': 'Xiomi',
            'category': 'mobile',
            'price': 10000,
            'color': 'grey'
        },
        {
            'type': 'phone',
            'name': 'redmi',
            'brand': 'Xiomi',
            'category': 'mobile',
            'price': 10000,
            'color': 'grey'
        }
    ]
    console.warn('product saga called', data);
    yield put({ type: SET_PRODUCT, data })
}