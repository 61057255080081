import { ADD_TO_CART, EMPTY_CART, GET_ALL_REGION, GET_ALL_REGION_SUCCESS, GET_ALL_ROLE, GET_ALL_ROLE_SUCCESS, GET_ALL_SITE, GET_ALL_SITE_SUCCESS, GET_ALL_USER, GET_ALL_ZONE, GET_ALL_ZONE_SUCCESS, GET_PRODUCT, REMOVE_TO_CART, SET_PRODUCT } from "../../constants/ActionTypes";

const ApiCall = (data = [], action) => {
    console.warn('actions', action);
    switch (action.type) {
        case SET_PRODUCT:
            console.warn('product reducer called', [...data]);
            return [...data];
        case GET_ALL_SITE_SUCCESS:
            console.warn('site reducer called', action.data);
            return action.data
        case GET_ALL_REGION_SUCCESS:
            console.warn('region reducer called');
            return "data"
        case GET_ALL_ROLE_SUCCESS:
            console.warn('region reducer called');
            return "data"
        case GET_ALL_ZONE_SUCCESS:
            console.warn('region reducer called');
            return "data"
        case ADD_TO_CART:
            console.warn('add cart reducer', action.product);
            return [action.product, ...data]
        case REMOVE_TO_CART:
            console.warn('remove cart reducer', data);
            data.length = data.length && data.length - 1
            return [...data]
        case EMPTY_CART:
            console.warn('empty cart');
            data = []
            return [...data]
        default:
            console.warn('default', []);
            return [];
    }

    // if (action.type === GET_ALL_USER) {
    //     console.warn('reducer user called');
    // } else if (action.type === GET_ALL_SITE) {
    //     console.warn('reducer site called');

    // } else if (action.type === GET_ALL_REGION) {
    //     console.warn('region reducer called');
    // } else if (action.type === GET_ALL_ROLE) {
    //     console.warn('role reducer called');
    // } else {
    //     console.warn('zone reducer called');
    // }
    // return data

}

export default ApiCall;