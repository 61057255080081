// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';
    
// Api Call
export const GET_ALL_SITE = 'GET_ALL_SITE';
export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_ALL_ROLE = 'GET_ALL_ROLE';
export const GET_ALL_ZONE = 'GET_ALL_ZONE';
export const GET_ALL_REGION = 'GET_ALL_REGION';
export const GET_ALL_METER = 'GET_ALL_METER';
export const GET_ALL_METER_TOKEN = 'GET_ALL_METER_TOKEN';
export const GET_ALL_SITE_TOKEN = 'GET_ALL_SITE_TOKEN';
export const GET_TOKEN_BY_METER ='GET_TOKEN_BY_METER';
export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ZONE ='ADD_ZONE';
export const ADD_REGION ='ADD_REGION';
export const ADD_SITE ='ADD_SITE';
export const ADD_METER ='ADD_METER';
export const ADD_USER ='ADD-USER';
export const UPLOAD_USER ='UPLOAD_USER';
export const UPLOAD_SITE ='UPLOAD_SITE';
export const ASSIGN_SITE_TO_USER ='ASSIGN_SITE_TO_USER';
export const ASSIGN_ROLE_TO_USER='ASSIGN_ROLE_TO_USER';
export const REMOVE_SITE_FROM_USER='REMOVE_SITE_FROM_USER';
export const REMOVE_ROLE_FROM_USER='REMOVE_ROLE_FROM_USER';
export const GET_USER_SITES='GET_USER_SITES';


// SUCCESS API Call
export const GET_ALL_SITE_SUCCESS = 'GET_ALL_SITE_SUCCESS';
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';
export const GET_ALL_ROLE_SUCCESS = 'GET_ALL_ROLE_SUCCESS';
export const GET_ALL_ZONE_SUCCESS = 'GET_ALL_ZONE_SUCCESS';
export const GET_ALL_REGION_SUCCESS = 'GET_ALL_REGION_SUCCESS';
export const GET_ALL_METER_SUCCESS = 'GET_ALL_METER_SUCCESS';
export const GET_ALL_METER_TOKEN_SUCCESS = 'GET_ALL_METER_TOKEN_SUCCESS';
export const GET_ALL_SITE_TOKEN_SUCCESS = 'GET_ALL_SITE_TOKEN_SUCCESS';
export const GET_TOKEN_BY_METER_SUCCESS ='GET_TOKEN_BY_METER_SUCCESS';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ZONE_SUCCESS ='ADD_ZONE_SUCCESS';
export const ADD_REGION_SUCCESS ='ADD_REGION_SUCCESS';
export const ADD_SITE_SUCCESS ='ADD_SITE_SUCCESS';
export const ADD_METER_SUCCESS ='ADD_METER_SUCCESS';
export const ADD_USER_SUCCESS ='ADD_USER_SUCCESS';
export const UPLOAD_USER_SUCCESS ='UPLOAD_USER_SUCCESS';
export const UPLOAD_SITE_SUCCESS ='UPLOAD_SITE_SUCCESS';
export const ASSIGN_SITE_TO_USER_SUCCESS ='ASSIGN_SITE_TO_USER_SUCCESS';
export const ASSIGN_ROLE_TO_USER_SUCCESS='ASSIGN_ROLE_TO_USER_SUCCESS';
export const REMOVE_SITE_FROM_USER_SUCCESS='REMOVE_SITE_FROM_USER';
export const REMOVE_ROLE_FROM_USER_SUCCESS='REMOVE_ROLE_FROM_USER_SUCCESS';
export const GET_USER_SITES_SUCCESS='GET_USER_SITES_SUCCESS';

// Cart 
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_TO_CART ='REMOVE_TO_CART';
export const EMPTY_CART ='EMPTY_CART';
// Product
export const GET_PRODUCT ='GET_PRODUCT'
export const SET_PRODUCT = 'SET_PRODUCT'

// 
