import axios from "axios";
import axios_ from "../../util/axios";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { GET_ALL_SITE, GET_ALL_SITE_SUCCESS, GET_PRODUCT, SET_PRODUCT } from "../../constants/ActionTypes";
import { handleGetSite,handleGetProduct } from "./handler/handler";


export function* productSaga() {
    yield takeEvery(GET_PRODUCT, handleGetProduct)
}

export function* siteSaga() {
    yield takeEvery(GET_ALL_SITE, handleGetSite)
}


export default function* rootSaga() {
    yield all([
        fork(siteSaga),
        fork(productSaga),
        //   fork(signOutUser)
    ]);
}
